// infiniteScroll
var isLoading = false;
var infiniteScrollUrl = "https://" + location.host + location.pathname;
function infiniteScroll($trigger, $wrap) {
    $(window).on('scroll',function(){
        if( $(window).width() < 769) {
            var triggerNodePosition = $trigger.offset().top - $(window).height();

            if($(window).scrollTop() > triggerNodePosition - 350 && isLoading == false) {

                var currentpage = Number($trigger.attr('data-currentpage'));
                var next = currentpage + 1;
                var url = infiniteScrollUrl;

                isLoading = true;
                $.ajax({
                    type: 'GET',
                    url: url,
                    data: {
                        page: next
                    },
                    timeout: 10000,
                    success: function (html) {
                        if(html == null || html == "") {
                            $trigger.parent().css('display', 'none');
                        } else {
                            $wrap.append(html);
                            $trigger.attr('data-currentpage', next);
                            ga('send', 'pageview', { 'page' : location.pathname + '?page=' + next});
                            $(document).foundation();
                        }
                    },
                    error: function(xhr, textStatus, error) {
                    },
                    complete: function (xhr, textStatus) {
                        isLoading = false;
                    }
                });
            }
        }
    });
}

// globalnavが1200以下でもスクロール可能
$(function(){
    var windowWidth = $(window).width();

    if(windowWidth > 768) {
        $(window).on("scroll", function(){
            $(".global-nav").css("left", -$(window).scrollLeft());
        });
    }
});

// 上に戻るボタン
$(function(){
    var $bttt = $('#back-to-the-top');
    $bttt.hide();

    $(window).scroll(function(){
        if($(this).scrollTop() > 100) {
            $bttt.fadeIn();
        } else {
            $bttt.fadeOut();
        }
    });

    $bttt.on('click', function () {
        event.preventDefault();
        $('.main,body,html').animate({
            scrollTop: 0
        }, 200);
        return false;
    });
});

// ローディング画面表示
function displayLoading() {
    $('<div id="loading-view" > Now Loading...</div>').appendTo('body').hide().fadeIn(250);
}

// ローディング画面非表示
function removeLoading() {
    $('#loading-view').fadeOut(250).queue(function() {
        this.remove();
    });
}

// 「観たい」「観た」のAjax送信
$(function() {
    $(document).on('submit', '.form-seen form', function(event) {
        var $form = $(this);
        var method = $form.find('input[name="_method"]').attr('value');
        if (method == null) {
            method = $form.attr('method');
        }

        // Htmlでの送信をキャンセル
        event.preventDefault();

        // 送信ボタンの無効化
        $form.parent('.form').find('input[type="submit"]').attr('disabled', true);

        // モーダルを閉じる
        $form.parents('div.reveal').foundation('close');

        // ローディング画面発動
        displayLoading();

        $.ajax({
            url: $form.attr('action'),
            type: method,
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: $form.serialize(),
            timeout: 10000,
            success: function(result, textStatus, xhr) {
                // 画面をリロード(引数をtrueにするとキャッシュを読み込まない)
                var formId = $form.parents('.form-seen').attr('id');
                var $btn = $('a[data-open="'+formId+'"]');
                var count = $btn.children('.num').text() - 0;
                if(method == 'POST'){
                    $btn.addClass("already").children('.num').text(++count);
                } else if (method == 'DELETE') {
                    $btn.removeClass("already").children('.num').text(--count);
                    $(".post-" + result.post.id).remove();
                } else if (method == 'PATCH') {
                    $(".post-" + result.post.id).find('.review p').text(result.post.comment);
                    $(".post-" + result.post.id).find('.rating .rating-score').text(parseFloat(result.post.rating).toFixed(1));
                }
                $form.parents('.form-seen').replaceWith(result.formHtml);
                $(document).foundation();
            },
            error: function(xhr, textStatus, error) {
            },
            complete: function (xhr, textStatus) {
                // 送信ボタンを有効化
                $form.parent('.form').find('input[type="submit"]').attr('disabled', false);
                removeLoading();
            }
        });
    });

    $(document).on('submit', '.form-want form', function(event) {
        var $form = $(this);
        var method = $form.find('input[name="_method"]').attr('value');
        if (method == null) {
            method = $form.attr('method');
        }

        // Htmlでの送信をキャンセル
        event.preventDefault();

        // 送信ボタンの無効化
        $form.parent('.form').find('input[type="submit"]').attr('disabled', true);

        // モーダルを閉じる
        $form.parents('div.reveal').foundation('close');

        // ローディング画面発動
        displayLoading();

        $.ajax({
            url: $form.attr('action'),
            type: method,
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: $form.serialize(),
            timeout: 10000,
            success: function(result, textStatus, xhr) {
                // 画面をリロード(引数をtrueにするとキャッシュを読み込まない)
                var formId = $form.parents('.form-want').attr('id');
                var $btn = $('a[data-open="'+formId+'"]');
                var count = $btn.children('.num').text() - 0;
                if(method == 'POST'){
                    $btn.addClass("already").children('.num').text(++count);
                } else if (method == 'DELETE') {
                    $btn.removeClass("already").children('.num').text(--count);
                    $(".post-" + result.post.id).remove();
                } else if (method == 'PATCH') {
                    $(".post-" + result.post.id).find('.review p').text(result.post.comment);
                }
                $form.parents('.form-want').replaceWith(result.formHtml);
                $(document).foundation();
            },
            error: function(xhr, textStatus, error) {
            },
            complete: function (xhr, textStatus) {
                // 送信ボタンを有効化
                $form.parent('.form').find('input[type="submit"]').attr('disabled', false);
                removeLoading();
            }
        });
    });
});

// disabledクラスのクリック無効
$(function(){
    $(document).on('click', '.disabled', function(){
        return false;
    });
});

// 「観た」投稿フォームの評価部分
$(function() {
    /* ドラッグ */
    $(document).on('input', '.form-seen input[type=range]', function () {
    	var val = parseFloat($(this).val()).toFixed(1);
    	$(this).next('.rating-value').text(val);
    });

    /* 変更後 */
    $(document).on('change', '.form-seen input[type=range]', function () {
    	var val = parseFloat($(this).val()).toFixed(1);
    	$(this).next('.rating-value').text(val);
    });
});

// 「観た」投稿フォームのネタバレハッシュタグ挿入
$(function() {
    $(document).on('click', '.btn-spoiler', function() {
        var $box = $(this).parents('.form').find('.ta-comment');
        $box.val($box.val() + ' #ネタバレ');
        return false;
    });
});

// 映画を見ている場合クリック無効
$(function() {
    $(document).on('click', '.already-seen', function() {
        // alert('すでにこの作品を見ています！');
        return false;
    });
});

// ネタバレ表示
$(function() {
    $(document).on('click', '.spoiler-warning', function() {
        $(this).css('display','none').parent('.spoiler-wrap').next('.spoiler-text').css('display', 'block');
        return false;
    });
});

// コメントフォーカス
$(function() {
    $(document).on('click', '.btn-comment', function() {
        $(this).parents('.post').find('.comment_form > textarea').focus();
        return false;
    });
});

$(function() {
    // コメント送信
    $(document).on('submit', '.comment_form', function(event) {
        event.preventDefault(); // html送信をキャンセル
        var $form = $(this);
        var $button = $form.find('.button');
        var value = $form.children('textarea').val();
        var username = $form.children('.username_hidden').val();
        if(value === '') { // 文字が入力されてないとき
            // alert('文字が入力されてません！');
            return false;
        }

        $.ajax({ // ajaxで送信
            url: $form.attr('action'),
            type: $form.attr('method'),
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: $form.serialize(),
            timeout: 10000,
            beforeSend: function(xhr, settings) {
                // ボタンを無効化し、二重送信を防止
                $button.attr('disabled', true);
            },
            // 応答後
            complete: function(xhr, textStatus) {
                // ボタンを有効化し、再送信を許可
                $button.attr('disabled', false);
            },
            success: function(result, textStatus, xhr){ // 成功したら
                var userimg = $form.parents('.main').find('.top-userimg>a>img').attr('src');
                var added = '<div class="comment-item"><a class="comment-item-left userImg" href="' + username + '"><img width="32" height="32" src="' + userimg + '" alt="' + username + '"></a><div class="comment-item-right"><a class="userName" href="' + username + '">' + username + '</a>' + result + '</div></div>';
                $form.parents('.comment-wrap').children('.comment-inner').append(added);
                var count = parseInt($form.parents('.post').find('.count-comment').text());
                $form.parents('.post').find('.count-comment').text(count + 1);
                $form[0].reset();
                // alert('コメントを送信しました！');
            },
            error: function(xhr, textStatus, error) { // 失敗したら
                // alert('失敗しました');
            }
        });
        return false;
    });

    // いいね！送信
    $(document).on('click', '.like', function(event){
        event.preventDefault(); // html送信をキャンセル

        var $link = $(this);
        if ( $(this).hasClass('disabled') ){
            return false;
        }
        $(this).addClass('disabled').css('opacity', 0.5);
        var post_id = $link.data('post-id');
        var username = $link.data('username');

        $.ajax({ // ajaxで送信
            url: 'https://' + location.host + '/likes',
            type: "POST",
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: {post_id: post_id},
            timeout: 10000,
            success: function(result, textStatus, xhr){ // 成功したら
                // count増やす
                var count = parseInt($link.parents('.post').find('.count-like').text());
                $link.parents('.post').find('.count-like').text(count + 1);
                // 要素追加
                var userimg = $link.parents('.post').find('.comment-form-img img').attr('src');
                var added = '<a href="/' + username + '"><img width="32" height="32" alt="' + username+ '" src="' + userimg + '">' + '</a>';

                $link.parents('.post').find('.post-like').append(added).removeClass('hide');
                // like を unlikeに
                $link.attr('class','unlike');
                // alert('いいねを追加しました！');
            },
            error: function(xhr, textStatus, error) { // 失敗したら
                // alert('エラー：正しく処理されませんでした。');
            },
            complete: function(xhr, textStatus) { // ボタンを有効化し、再送信を許可
                $link.removeClass('disabled').css('opacity', 1).unbind('mouseenter').unbind('mouseleave');
            }
        });
        return false;
    });

    // いいね！削除
    $(document).on('click', '.unlike', function(event){
        event.preventDefault(); // html送信をキャンセル

        var $link = $(this);
        if ( $(this).hasClass('disabled') ){
            return false;
        }
        $link.addClass('disabled').css('opacity', 0.5);
        var post_id = $link.data('post-id');
        var username = $link.data('username');

        $.ajax({ // ajaxで送信
            url: 'https://' + location.host + '/likes/' + post_id,
            type: "DELETE",
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: {post_id: post_id},
            timeout: 10000,
            success: function(result, textStatus, xhr){ // 成功したら
                // count減らす
                var count = parseInt($link.parents('.post').find('.count-like').text());
                $link.parents('.post').find('.count-like').text(count - 1);
                // 要素削除
                $link.parents('.post').find('.post-like').find('a[href*=' + username +']').remove();
                // unlike を likeに
                $link.attr('class','like');
                // alert('いいねを削除しました');
            },
            error: function(xhr, textStatus, error) { // 失敗したら
                // alert('エラー：正しく処理されませんでした。');
            },
            complete: function(xhr, textStatus) { // ボタンを有効化し、再送信を許可
                $link.removeClass('disabled').css('opacity', 1).unbind('mouseenter').unbind('mouseleave');
            }
        });

        return false;
    });
});

// フォロー
$(function() {
    $(document).on('click', 'button.follow', function() {
        var $followBtn = $(this);
        $followBtn.addClass('disabled');
        var user_name = $followBtn.data('username');

        $.ajax({
            type: 'POST',
            url: 'https://' + location.host + '/users/follow',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: {user_name: user_name},
            timeout: 10000,
            success: followSuccessed(user_name),
            error: function(xhr, textStatus, error) {

            },
            complete: function(xhr, textStatus) {
                $followBtn.removeClass('disabled');
            }
        });

        // フォローボタンをアンフォローボタンに変更
        function followSuccessed(user_name) {
            return function(result) {
                if (result === 'true') {
                    $('button.follow[data-username=' + user_name + ']').replaceWith('<button class="button primary small hollow unfollow round" data-username="' + user_name +'"><span class="button-text">フォロー中</span></button>');
                }
            }
        }
    });

    // アンフォロー
    $(document).on('click', 'button.unfollow', function() {
        var $unfollowBtn = $(this);
        $unfollowBtn.addClass('disabled');
        var user_name = $unfollowBtn.data('username');

        $.ajax({
            type: 'POST',
            url: 'https://' + location.host + '/users/unfollow',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            data: {user_name: user_name},
            timeout: 10000,
            success: unfollowSuccessed(user_name),
            error: function(xhr, textStatus, error) {

            },
            complete: function(xhr, textStatus) {
                $unfollowBtn.removeClass('disabled');
            }
        });

        // アンフォローボタンをフォローボタンに変更
        function unfollowSuccessed(user_name) {
            return function(result) {
                if (result === 'true') {
                    $('button.unfollow[data-username=' + user_name + ']').replaceWith('<button class="button secondary small hollow follow round" data-username="' + user_name + '"><span class="button-text">フォロー</span></button>');
                }
            }
        }
    });
});

// 登録画面のリアルタイムバリデーション
$(function() {
    var alphaCheck = /[A-Za-z0-9]+/; // 英数字 TODO 使わなければ消す
    var alphaDashCheck = /^[\w\-]+$/; // 英数字、アンスコ、ハイフン
    var mailCheck = /^[\w_-]+@[\w\.-]+\.\w{2,}$/; // メールの形式

    var urlForCheckValue = "/check/";

    var formNotice = '<div class="form-notice"></div>';
    function getFormNotice(noticeMsg) {
        var newFormNotice = '<div class="form-notice">' + noticeMsg + '</div>'
        return newFormNotice;
    }

    $inputUserName = $('.register input[name="user_name"]');
    $inputUserName.on('blur', function(e) {
        $(this).siblings('.form-notice').remove(); // noticeを一旦削除
        var isUsed = false;

        if($(this).val() == "") { // 空欄なら
            formNotice = getFormNotice("ユーザーネームは必須項目です。");
            $(this).after(formNotice);
        } else if( !$(this).val().match(alphaDashCheck) ) {
            formNotice = getFormNotice("英数字、-(ハイフン)、_(アンダーバー)以外の文字が使われています。");
            $(this).after(formNotice);
        } else if( $(this).val().length >= 21) { // 21文字以上なら
            formNotice = getFormNotice("20文字を超えています。");
            $(this).after(formNotice);
        } else if($(this).val().length <= 3){ // 3文字以下なら
            formNotice = getFormNotice("ユーザーネームは4文字以上にしてください。");
            $(this).after(formNotice);
        } else if($(this).val()) {
            $.ajax({
                type: 'GET',
                url: urlForCheckValue + $(this).val(),
                timeout: 10000,
                // async: false,
                success: function(data) {
                    isUsed = Boolean(data.isUsed);
                    if(isUsed) {
                        formNotice = getFormNotice("このユーザーネームは使われています。");
                        $inputUserName.after(formNotice);
                    } else {
                        $inputUserName.siblings('.form-notice').remove();
                    }
                },
                error: function(xhr, textStatus, error) {
                    return false;
                }
            });
        }
    });

    $inputScreenName = $('.register input[name="screen_name"]');
    $inputScreenName.on('blur', function(e) {
        $(this).siblings('.form-notice').remove();// noticeを一旦削除

        if($(this).val().length >= 16) { // 16文字以上なら
            formNotice = getFormNotice("15文字を超えています。");
            $(this).after(formNotice);
        } else {
            $(this).siblings('.form-notice').remove();
        }
    });

    $inputUserMail = $('.register input[name="user_mail"]');
    $inputUserMail.blur(function(e) {
        $(this).siblings('.form-notice').remove();// noticeを一旦削除

        if($(this).val() == "") { // 空欄なら
            formNotice = getFormNotice("メールアドレスは必須項目です。");
            $(this).after(formNotice);
        } else if( !$(this).val().match(mailCheck) ) { // メールの形式で無かったら
            formNotice = getFormNotice("正しいメールアドレスの形式ではありません。");
            $(this).after(formNotice);
        } else if($(this).val()) {
            $.ajax({
                type: 'GET',
                url: urlForCheckValue + $(this).val(),
                timeout: 10000,
                // async: false,
                success: function(data) {
                    isUsed = Boolean(data.isUsed);
                    if(isUsed) {
                        formNotice = getFormNotice("このメールアドレスは使われています。");
                        $inputUserMail.after(formNotice);
                    } else {
                        $inputUserMail.siblings('.form-notice').remove();
                    }
                },
                error: function(xhr, textStatus, error) {
                    return false;
                }
            });
        }
    });

    $inputUserPassword = $('.register input[name="user_password"]');
    $inputUserPassword.blur(function(e) {
        $(this).siblings('.form-notice').remove();// noticeを一旦削除

        if($(this).val() == "") { // 空欄なら
            formNotice = getFormNotice("パスワードは必須項目です。");
            $(this).after(formNotice);
        } else if($(this).val().length <= 5){ // 5文字以下なら
            formNotice = getFormNotice("パスワードは6文字以上にしてください。");
            $(this).after(formNotice);
        } else {
            $(this).siblings('.form-notice').remove();
        }
    });

    $inputUserPasswordConfirmation = $('.register input[name="user_password_confirmation"]');
    $inputUserPasswordConfirmation.blur(function(e) {
        $(this).siblings('.form-notice').remove();// noticeを一旦削除

        if($(this).val() == "") { // 空欄なら
            formNotice = getFormNotice("パスワード再入力は必須項目です。");
            $(this).after(formNotice);
        } else if($(this).val().length <= 5){ // 5文字以下なら
            formNotice = getFormNotice("パスワード再入力は6文字以上にしてください。");
            $(this).after(formNotice);
        } else {
            $(this).siblings('.form-notice').remove();
        }
    });

    $inputTermsOfService = $('.register input[name="terms_of_service"]');
    $buttonSubmit = $('.register button[type="submit"]');
    $buttonSubmit.on("click", function(){
        // Mixpanel 登録ボタン イベント
        mixpanel.track("Click SubmitBtn In Register Form", { "inputUserNameValue": $inputUserName.val() });
    });
    /*
    $buttonSubmit.attr("disabled","disabled");

    $inputTermsOfService.click(function(){
        if($(this).prop('checked') == false){
            $buttonSubmit.attr("disabled","disabled");
        } else {
            $buttonSubmit.removeAttr('disabled');
        }
    });
    */
});
